
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import Signup from './SignupForm.vue'
import ResetPassword from './ResetPassword.vue'
import VerifyEmail from './VerifyEmail.vue'
import ModalNewPassword from './ModalNewPassword.vue'
import RequestAccess from './RequestAccess.vue'
import { ElMessage } from 'element-plus'
interface Result {
  data: []
  message: string
  status: boolean
}
@Options({
  components: {
    Signup,
    ResetPassword,
    VerifyEmail,
    ModalNewPassword,
    RequestAccess,
  },
})
export default class SigninForm extends Vue {
  refForm = 'signInForm'
  dataSignIn = {
    email: '',
    password: '',
    expireVerifyHash: new Date(),
  }
  formSignUp = {
    nameAccount: '',
    email: '',
    password: '',
    passwordConfirm: '',
    expireVerifyHash: new Date(),
  }
  dataFormResetPasswordFirstLogin = {
    password: '',
    passwordConfirm: '',
    email: '',
  }
  currentForm = 'login'
  showHeader = true
  doSignIn = false
  doSignUp = false
  doResetPasswordFirstLogin = false
  tokenResetPassword: any
  siginInResponse: any
  notTokenResetPassword = true
  rules = {
    nameAccount: [
      {
        required: true,
        message: 'Please input account name',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'Please input email address',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: 'Please input correct email address',
        trigger: ['blur', 'change'],
      },
    ],
    password: [
      {
        required: true,
        message: 'Please input password',
        trigger: 'blur',
      },
      {
        min: 6,
        max: 25,
        message: 'password must be at least 6 characters',
        trigger: 'blur',
      },
    ],
    passwordConfirm: [
      {
        required: true,
        message: 'Please input password again',
        trigger: 'blur',
      },
      {
        min: 6,
        max: 25,
        message: 'Password must be at least 6 characters',
        trigger: 'blur',
      },
    ],
  }
  async created() {
    this.tokenResetPassword = this.$route.query.token?.toString()

    if (this.tokenResetPassword) {
      this.notTokenResetPassword = false
      this.checkTokenResetPassword()
    }
  }
  async checkTokenResetPassword() {
    let dataForm = {
      tokenResetPassword: this.tokenResetPassword,
    }
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result = await axios.post('/confirm-reset-password', dataForm)
    if (res.message) {
      this.notTokenResetPassword = true
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async submitForm() {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    let path = await localStorage.getItem('redirectPath')

    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })
    // call api
    const siginInResponse = await this.$store.dispatch(
      'auth/signIn',
      this.dataSignIn,
    )
    this.siginInResponse = siginInResponse
    await this.$store.dispatch('setLoading', false, { root: true })
    if (siginInResponse.tokenUser) {
      if (siginInResponse.fisrtLogin) {
        this.doResetPasswordFirstLogin = true
        this.currentForm = 'ResetPasswordFirstLogin'
        return console.log('Reset Password First Login')
      }
      await this.$store.dispatch('auth/setTokenUser', siginInResponse.tokenUser)

      if (path === '') {
        this.$router.push('/get-started')
      }
      if (path !== '') {
        this.$router.push(path || '/get-started')
      } else await this.$router.push({ name: 'GetStartedPage' })
      await localStorage.setItem('redirectPath', '')

      // if (path === "") {
      //   location.reload();
      // }
    } else if (siginInResponse.status) {
      this.currentForm = 'verify_email'
      this.showHeader = false
      this.doSignIn = true
      this.dataSignIn.expireVerifyHash = new Date(Date.now() + 15 * 60000)
    } else {
      this.$message.warning(
        siginInResponse.message || JSON.stringify(siginInResponse),
      )
      return
    }
  }
  async submitFormSignUp() {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })
    // call api
    const siginUpResponse = await this.$store.dispatch(
      'auth/signUp',
      this.formSignUp,
    )
    if (siginUpResponse.message === 'Account sign up successful !!!') {
      setTimeout(() => {
        let formSignUp = {
          email: this.formSignUp.email,
          password: this.formSignUp.password,
        }
        this.signUp(formSignUp)
      }, 200)
    } else
      this.$message.warning(
        siginUpResponse.message || JSON.stringify(siginUpResponse),
      )
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async handleResetPassword(obj: any) {
    this.doResetPasswordFirstLogin = true
    this.currentForm = 'ResetPasswordFirstLogin'
    this.doSignIn = false
    this.showHeader = true
    this.siginInResponse = obj
  }

  async submitFormResetPasswordFirstLogin() {
    this.dataFormResetPasswordFirstLogin.email = this.dataSignIn.email
    let regexPassword =
      /(?=^.{8,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/

    let res: Result
    if (
      regexPassword.exec(this.dataFormResetPasswordFirstLogin.password) == null
    ) {
      return ElMessage.error(
        'Password 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 8-20 characters !!!',
      )
    } else if (
      this.dataFormResetPasswordFirstLogin.password !==
      this.dataFormResetPasswordFirstLogin.passwordConfirm
    )
      return this.$message.warning('Please match 2 password fields!', 'Error')
    await this.$store.dispatch('setLoading', true, { root: true })
    // call api
    res = await axios.post(
      `reset-password-first-login`,
      this.dataFormResetPasswordFirstLogin,
    )
    if (res.status) {
      this.$message.success(res.message)
      if (this.siginInResponse.tokenUser) {
        await this.$store.dispatch(
          'auth/setTokenUser',
          this.siginInResponse.tokenUser,
        )
        let path = await localStorage.getItem('redirectPath')
        if (path === '') {
          this.$router.push('/get-started')
        }
        if (path !== '') {
          this.$router.push(path || '/get-started')
        } else await this.$router.push({ name: 'GetStartedPage' })
        await localStorage.setItem('redirectPath', '')
      }
    } else {
      await this.$store.dispatch('setLoading', false, { root: true })
      return this.$message.warning(res.message, 'Error')
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async signUp(formSignUp: any) {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result
    res = await axios.post(`sign-in`, formSignUp, {
      params: {
        type: 'signUp',
      },
    })
    if (res.status) {
      this.currentForm = 'verify_email'
      this.showHeader = false
      this.doSignUp = true
      this.formSignUp.expireVerifyHash = new Date(Date.now() + 15 * 60000)
    } else {
      this.$message.error(res.message)
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async doResetPassword(formSignIn: any) {
    this.dataSignIn = formSignIn
    let res: Result
    res = await axios.post(`sign-in`, formSignIn, {})
    if (res.status) {
      this.notTokenResetPassword = true
      this.currentForm = 'verify_email'
      this.showHeader = false
      this.doSignIn = true
    }
  }
}
