const platforms = ['ChannelAdvisor', 'BigCommerce', 'Esty', 'Magento2', 'HyperMule', 'Linnworks','Peoplevox', 'Shopify','Tiktok', 'Woocommerce','Alphabroder', 'QuickBooks', 'Prestashop', 'VisualSoft', 'Lightspeed', 'Brightpearl', 'Odoo', 'Xentral', 'Essendant', 'lightspeedX','Netsuite', 'Xero', 'Portica', 'Aims360', 'SapB1', 'Dynamics365', 'Teapplix', 'warner', 'CommerceHub', 'Myflex', 'Dynamics AX EDI', 'Convictional', 'Dynamicsfo', 'SapB1FileExport', 'ChannelAdvisorFileImport',];
interface simpleInt {
    hub: string;
    target: string;
    price: number
}
const list: Array<simpleInt> = [];
platforms.forEach((platformFrom: any) => {
    platforms.forEach((platformTo: any) => {
        if (platformFrom != platformTo) {
            list.push({ hub: platformFrom, target: platformTo, price: 0 })
        }
    })
})

export default {list,platforms}

