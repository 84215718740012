
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import listConnectAvailable from '../../../store/platform'
import { ElMessage, ElMessageBox } from 'element-plus'

interface Result {
  data: []
  message: string
  status: boolean
}
@Options({
  components: {},
})
export default class RequestAccess extends Vue {
  refForm = 'signUpForm'
  formRequest = {
    name: '',
    email: '',
    phone: '',
    company: '',
    conections: [],
  }
  currentForm = 'login'
  showHeader = true
  list: any = []
  expireVerifyHash = new Date()
  rules = {
    name: [
      {
        required: true,
        message: 'Please input your name',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'Please input email address',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: 'Please input correct email address',
        trigger: ['blur', 'change'],
      },
    ],
    phone: [
      {
        min: 14,
        max: 14,
        message: 'Must be a correct phone number.',
        trigger: 'blur',
      },
    ],
    ConnectorsOfInterest: [
      {
        required: true,
        message: 'Please choose at least 1 connector',
        trigger: 'blur',
      },
    ],
  }
  async created() {
    listConnectAvailable.list.forEach((connect) => {
      this.list.push({
        name: connect.hub.toUpperCase() + ' - ' + connect.target.toUpperCase(),
      })
    })
  }
  handleUserInput(e: any) {
    var x = e.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    this.formRequest.phone = !x[2]
      ? x[1]
      : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }
  async submitForm() {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.post(`request-early-access`, this.formRequest, {})
    if (res.status) {
      ElMessageBox.alert(
        `
            <h2 style="color:#215ca2"> Thank you for your interest in iOmniC!</h2>
            <p> A iOmniC representative will contact you shortly.</p>
         `,
        {
          dangerouslyUseHTMLString: true,
        },
      )
      this.formRequest = {
        name: '',
        email: '',
        phone: '',
        company: '',
        conections: [],
      }
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
}
